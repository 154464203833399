<template>
    <div>
        <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false">
            <md-content>
                <div class="dialog-header">
                    <div class="dialog-title"><span>Danh sách đổ dầu</span></div>
                    <div style="display: flex;">
                        <a class="dialog-submit" @click="submit()">Submit</a>
                        <div class="dialog-close" @click="showDialog = false">Đóng</div>
                    </div>
                </div>
                <div class="dialog-search">
                    <div class="row">
                        <div class="col l-3 m-3 c-12">
                            <md-autocomplete v-model="driverName" @md-selected="getDriverSelected" :md-options="drivers" @md-changed="getDrivers"  @md-opened="getDrivers">
                                <label v-if="search.driverId == 0">Lái xe</label>
                                <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                    <md-highlight-text :md-term="term">{{ item.fullName }}</md-highlight-text>
                                </template>
                                <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                    "{{ term }}" Không tìm thấy!
                                </template>
                            </md-autocomplete>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-autocomplete v-model="licensePlate" @md-selected="getVehicleSelected" :md-options="vehicles" @md-changed="getVehicles"  @md-opened="getVehicles">
                                <label v-if="search.vehicleId == 0">Số xe</label>
                                <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                    <md-highlight-text :md-term="term">{{ item.licensePlate }}</md-highlight-text>
                                </template>
                                <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                    "{{ term }}" Không tìm thấy!
                                </template>
                            </md-autocomplete>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-datepicker v-model="search.fromDate" :md-model-type="String" md-immediately>
                                <label v-if="search.fromDate == '' || search.fromDate == null">Từ ngày</label>
                            </md-datepicker>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-datepicker v-model="search.toDate" :md-model-type="String" md-immediately>
                                <label v-if="search.toDate == '' || search.toDate == null">Tới ngày</label>
                            </md-datepicker>
                        </div>
                    </div>
                </div>
                <div class="dialog-content">
                    <div class="row">
                        <div class="col l-12 m-12 c-12">
                            <table class="data-table">
                                <thead>
                                    <tr>
                                        <th style="width: 50px;">#</th>
                                        <th rowspan="2" style="width:60px; vertical-align: middle;">
                                            <md-checkbox v-model="selectAll"></md-checkbox>
                                        </th> 
                                        <th style="width: 250px;">Lái xe</th>
                                        <th style="width: 220px;">Biển số</th>
                                        <th style="width: 150px;">Ngày đổ dầu</th>
                                        <th class="right" style="width: 150px;">Số lít</th>
                                    </tr>
                                </thead>
                                <tbody v-if="loadding == true" style="height: 150px;">
                                    <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                </tbody>
                                <tbody v-if="loadding == false && pager.totalPages == 0" style="height: 150px;">
                                    <tr><td colspan="100" class="no-data"><span>Không có dữ liệu</span></td></tr>
                                </tbody> 
                                <tbody v-if="loadding == false && pager.totalItems > 0">
                                    <tr v-for="(item, index) in data" :key="item.id" :class="{ 'odd': index % 2 !== 0 }">
                                        <td class="center">{{index + 1}}</td>
                                        <td class="center">
                                            <md-checkbox v-model="checkedList" :value="item.id"></md-checkbox>
                                        </td>
                                        <td><a @click="selected(item)">{{item.driver.fullName}}</a></td>
                                        <td class="center">{{item.vehicle.licensePlate}}</td>
                                        <td class="center">{{item.createdDate}}</td>
                                        <td class="right">{{ formatNumber(item.quantity) }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="dialog-footer" v-if="pager.totalPages > 0">
                    <div class="dialog-paging">
                        <div v-if="pager.totalPages > 0">
                            <v-pagination v-model="search.pageIndex"
                            :page-count="pager.totalPages"
                            @change="onChange"></v-pagination>
                        </div>
                    </div>
                    <div>|</div>
                    <div v-if="pager.totalPages > 0" class="dialog-data-info">Xem <span>{{pager.startIndex}}-{{pager.endIndex}}/{{pager.totalItems}}</span> bản ghi</div>
                    <div>|</div>
                    <div class="dialog-paging-row-of-page">Rows/page: <a v-for="item in rowPerPageOptions" :key="item.id" :class="{'current-row-of-page': search.pageSize == item.id}" @click="changeRowPerPage(item.id)">{{item.text}}</a></div>
                    <div>|</div>
                    <div class="dialog-refresh"><a @click="refresh()">Làm tươi</a></div>
                </div>
            </md-content>
        </md-dialog>
    </div>
</template>

<script>
    import fuelService from '../../api/fuelService'; 
    import messageBox from '../../utils/messageBox'; 
    import common from '../../mixins';
    import vPagination from '../Pagination.vue';
    import driverService from "../../api/driverService"
    import vehicleService from '../../api/vehicleService';

    export default {
        components: {
            vPagination
        },
        data: () => ({
            showDialog: false,
            ids: [],
            data: [],
            pager: { totalPages: 1 }, 
            loadding: false, 
            search: { pageIndex: 1, pageSize: common.pageSize, driverId: 0, vehicleId: 0, fromDate: '', toDate: '' },
            rowPerPageOptions: [{ text: '10', id: 10 }, { text: '15', id: 15 }, { text: '20', id: 20 }, { text: '25', id: 25 }, { text: '50', id: 50 }, { text: '100', id: 100 }],
            checkedList: [],
            selectAll: false,
            driverName: '',
            drivers: [],
            licensePlate: '',
            vehicles: [],
        }),
        created() {
        },
        methods: { 
            getVehicleSelected(val){
                this.search.vehicleId = val.id;
                this.licensePlate = val.licensePlate;
            },

            getVehicles(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, licensePlate:  searchTerm };
                vehicleService.getVehicles(search).then((response) => {
                    if(response.statusCode == 200){
                        this.vehicles = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            getDriverSelected(val){
                this.search.driverId = val.id;
                this.driverName = val.fullName;
            },

            getDrivers(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, code:  searchTerm };
                driverService.getDrivers(search).then((response) => {
                    if(response.statusCode == 200){
                        this.drivers = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            submit(){
                let list = [];
                for(let i = 0; i < this.checkedList.length; i++){
                    list.push(this.data.filter(x => x.id == this.checkedList[i])[0]);
                }
                this.$emit('close', list);
                this.showDialog = false;
            },

            selected(item){
                let list = [];
                list.push(item);
                this.$emit('close', list);
            },

            onChange: function () {
                this.getData();
            },

            open(){
                this.getData();
                this.showDialog = true;
            },

            close(){
                this.showDialog = false;
            },

            changeRowPerPage(pageSize){
                this.search = { pageIndex: 1, pageSize: pageSize, driverId: 0, vehicleId: 0, fromDate: '', toDate: '' };
                this.getData();
            },

            refresh() {
                this.search = { pageIndex: 1, pageSize: common.pageSize, driverId: 0, vehicleId: 0, fromDate: '', toDate: '' };
                this.getData();
            },

            getData(){
                this.loadding = true;
                fuelService.getForSubmited(this.search).then((response) => {
                    if(response.statusCode == 200){
                        this.data = response.data.items; 
                        this.pager = response.data.pager;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.loadding = false; }); 
            },
        },
        watch: { 
            selectAll: function (val){
                if(val == true){
                    this.checkedList = []
                    this.data.map((item) => {
                        this.checkedList.push(item.id);
                    })
                }
                else{
                    this.checkedList = []
                }
            },
            'search.pageIndex': function () { 
                this.getData(); 
            }, 
            'search.pageSize': function () { 
                this.search.pageIndex = 1; 
                this.getData(); 
            },
            'search.driverId': function () { 
                this.search.pageIndex = 1; 
                this.getData(); 
            },
            'search.vehicleId': function () { 
                this.search.pageIndex = 1; 
                this.getData(); 
            },
            'search.fromDate': function () { 
                this.search.pageIndex = 1; 
                this.getData(); 
            },
            'search.toDate': function () { 
                this.search.pageIndex = 1; 
                this.getData(); 
            } 
        }, 
    }
</script>

<style lang="css" scoped>
    .md-content {
        padding: 10px;
        width: 900px;
        height: 455px;
        max-width: 900px;
        max-height: 455px;
    }
    .dialog-content {
        height: 345px;
    }
    .dialog-content table{
        height: 340px;
        min-height: 340px !important;
    }
    .dialog-submit {
        color: #448aff;
        margin-right: 10px;
        cursor: pointer;
        font-weight: bold;
    }
    .dialog-submit:hover {
        text-decoration: underline !important;
    }
</style>