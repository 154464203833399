<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <h4 class="page-list-title"><md-icon>note_add</md-icon> <span>{{title}}</span></h4>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action"> 
                        <md-button v-shortkey="['ctrl', 'l']" @shortkey="submit()" @click="submit()" class="md-raised md-primary"><span>L</span>ưu<md-tooltip>Lưu (Ctrl + L)</md-tooltip></md-button>
                        <md-button v-shortkey="['ctrl', 'i']" @shortkey="back()" @click="back()" class="md-raised">Quay lạ<span>i</span><md-tooltip>Quay lại (Ctrl + Q)</md-tooltip></md-button>
                    </div>
                </div>
            </div>
            <div class="page-list-body">
               <div class="form-body">
                    <div class="row">
                        <div class="col l-3 m-3 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="driverName" @md-selected="getDriverSelected" :md-options="drivers" @md-changed="getDrivers"  @md-opened="getDrivers" :class="{'md-invalid': submitted && $v.entity.driverId.$error }">
                                    <label>Lái xe<span class="label-require">(*)</span></label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.fullName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && !$v.entity.driverId.isSelected">Vui lòng chọn lái xe</span>
                                </md-autocomplete>
                                <md-button @click="openDriver()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm lái xe</md-tooltip>
                                </md-button>
                            </div>
                            <div class="form-control">
                                <md-autocomplete v-model="licensePlate" @md-selected="getVehicleSelected" :md-options="vehicles" @md-changed="getVehicles"  @md-opened="getVehicles" :class="{'md-invalid': submitted && $v.entity.vehicleId.$error }">
                                    <label>Số xe<span class="label-require">(*)</span></label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.licensePlate }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && !$v.entity.vehicleId.isSelected">Vui lòng chọn xe</span>
                                </md-autocomplete>
                                <md-button @click="openVehicle()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm xe</md-tooltip>
                                </md-button>
                            </div>
                            <div class="form-control">
                                <md-autocomplete v-model="staffName" @md-selected="getStaffSelected" :md-options="staffs" @md-changed="getStaffs"  @md-opened="getStaffs" :class="{'md-invalid': submitted && $v.entity.staffId.$error }">
                                    <label>Nhân viên điều xe<span class="label-require">(*)</span></label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.fullName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && !$v.entity.staffId.isSelected">Vui lòng chọn nhân viên phụ trách</span>
                                </md-autocomplete>
                                <md-button @click="openStaff()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm nhân viên</md-tooltip>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-field>
                               <label for="normQuantity">Tổng dầu định mức</label>
                               <md-input :disabled="true" name="normQuantity" v-model="entity.normQuantity" ></md-input>
                            </md-field>
                            <md-field>
                               <label for="actualQuantity">Tổng dầu đổ thực tế</label>
                               <md-input :disabled="true" name="actualQuantity" v-model="entity.actualQuantity" ></md-input>
                            </md-field>
                            <md-field>
                               <label for="diffQuantity">Chênh lệch</label>
                               <md-input :disabled="true" name="diffQuantity" v-model="entity.diffQuantity" ></md-input>
                            </md-field>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-field style="padding-top: 0; flex-direction: column; align-items: left;" :class="{'md-invalid': submitted && $v.entity.price.$error }">
                                <div>Đơn giá(Đã bao gồm VAT):</div>
                                <number @change="calSum()" name="price" v-model="entity.price" v-bind="number"></number> 
                                <span class="md-error" v-if="submitted && !$v.entity.price.required">Vui lòng nhập đơn giá</span>
                            </md-field>
                            <md-field style="padding-top: 0; flex-direction: column; align-items: left;" :class="{'md-invalid': submitted && $v.entity.vat.$error }">
                                <div>Vat:</div>
                                <number name="vat" v-model="entity.vat" v-bind="number"></number> 
                                <span class="md-error" v-if="submitted && !$v.entity.vat.required">Vui lòng nhập vat</span>
                            </md-field>
                            <md-field style="padding-top: 0; flex-direction: column; align-items: left;" :class="{'md-invalid': submitted && $v.entity.amount.$error }">
                                <div>Thành tiền:</div>
                                <number :disabled="true" name="amount" v-model="entity.amount" v-bind="number"></number> 
                                <span class="md-error" v-if="submitted && !$v.entity.amount.required">Vui lòng nhập thành tiền</span>
                            </md-field>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-field>
                               <label for="km">Số Km đã chạy</label>
                               <md-input disabled name="km" v-model="entity.km" ></md-input>
                            </md-field>
                            <md-datepicker v-model="entity.createdDate" md-immediately :md-model-type="String" :class="{'md-invalid': submitted && $v.entity.createdDate.$error }">
                                <label>Ngày cấp dầu</label>
                                <span class="md-error" v-if="submitted && !$v.entity.createdDate.required">Vui lòng chọn ngày cấp dầu</span>
                            </md-datepicker>
                            <md-field>
                               <label for="note">Ghi chú</label>
                               <md-input name="note" v-model="entity.note" ></md-input>
                            </md-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col l-7 m-7 c-12">
                            <div style="display: flex; align-items: center;">
                                <div style="margin-right: 10px; font-weight: bold;">Danh sách mức dầu</div>
                                <div style="display: flex; margin: 5px 0;">
                                    <a @click="openJobRoute()" style="padding: 4px 7px; background-color: #4CC9FE; border-radius: 5px; color: #FFF;">Thêm định mức dầu</a>
                                </div>
                            </div>
                            <div class="job-routes">
                                <div class="row-head">
                                    <div class="cell">#</div>
                                    <div class="cell">Mã điều xe</div>
                                    <div class="cell">Lái xe</div>
                                    <div class="cell">Số xe</div>
                                    <div class="cell">Số KM</div>
                                    <div class="cell">Định mức(lít)</div>
                                </div>
                                <div class="body-list">
                                    <div v-for="(item, index) in entity.jobRouteList" :key="'route-' + index" class="box-row">
                                        <div class="cell grid-action">
                                            <a @click="delJobRoute(item.id)"><md-icon>clear</md-icon><md-tooltip>Xóa dòng</md-tooltip></a>
                                        </div>
                                        <div class="cell">{{ item.job.jobCode }}</div>
                                        <div class="cell">{{ item.job.driver.fullName }}</div>
                                        <div class="cell">{{ item.job.vehicle.licensePlate }}</div>
                                        <div class="cell">{{ item.km }}</div>
                                        <div class="cell">{{ formatNumber(item.fuel) }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col l-5 m-5 c-12">
                            <div style="display: flex; align-items: center;">
                                <div style="margin-right: 10px; font-weight: bold;">Danh sách đổ dầu</div>
                                <div style="display: flex; margin: 5px 0;">
                                    <a @click="openFuel()" style="padding: 4px 7px; background-color: #4CC9FE; border-radius: 5px; color: #FFF;">Thêm đổ dầu</a>
                                </div>
                            </div>
                            
                            <div class="fuel-list">
                                <div class="row-head">
                                    <div class="cell">#</div>
                                    <div class="cell">Lái xe</div>
                                    <div class="cell">Số xe</div>
                                    <div class="cell">Ngày cấp</div>
                                    <div class="cell">Số lít</div>
                                </div>
                                <div class="body-list">
                                    <div v-for="(item, index) in entity.fuelList" :key="'fuel-' + index" class="box-row">
                                        <div class="cell grid-action">
                                            <a @click="delFuel(item.id)"><md-icon>clear</md-icon><md-tooltip>Xóa dòng</md-tooltip></a>
                                        </div>
                                        <div class="cell">{{ item.driver.fullName }}</div>
                                        <div class="cell">{{ item.vehicle.licensePlate }}</div>
                                        <div class="cell">{{ item.createdDate }}</div>
                                        <div class="cell">{{ formatNumber(item.quantity) }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <staffList ref="staffList" @close="closeStaff"/>
        <driverList ref="driverList" @close="closeDriver"/>
        <vehicleList ref="vehicleList" @close="closeVehicle"/>
        <jobRouteList ref="jobRouteList" @close="closeJobRoute"/>
        <fuelList ref="fuelList" @close="closeFuel"/>
    </div>
</template>

<script>
    import fuelSubmitedService from '../../../api/fuelSubmitedService';
    import staffService from "../../../api/staffService"
    import driverService from '../../../api/driverService';
    import vehicleService from '../../../api/vehicleService';
    import messageBox from '../../../utils/messageBox';
    import { required } from 'vuelidate/lib/validators';
    import { mapActions } from 'vuex';
    import common from '../../../mixins';
    import staffList from '../../../components/popup/_StaffList.vue';
    import driverList from '../../../components/popup/_DriverList.vue';
    import vehicleList from '../../../components/popup/_VehicleList.vue';
    import jobRouteList from '../../../components/popup/_JobRouteSubmitedList.vue';
    import fuelList from '../../../components/popup/_FuelSubmitedList.vue';

    export default ({
        components: {
            driverList,
            vehicleList,
            staffList,
            jobRouteList,
            fuelList
        },
        metaInfo: {
            title: 'Thêm/Cập nhật chốt dầu'
        },
        data() {
            return {
                showDialog: false,
                title: '',
                id: 0,
                submitted: false,
                entity: { id: 0, createdDate: common.dateNow, note: '', jobRouteList: [], fuelList: [], driverId: 0, vehicleId: 0, normQuantity: 0, actualQuantity: 0, diffQuantity: 0, price: 0, vat: 0, amount: 0 },
                saveAndCreate: false,
                driverName: '',
                drivers: [],
                licensePlate: '',
                vehicles: [],
                staffName: '',
                staffs: [],
                number: common.currencyF2,
                selectAllJobRoute: false,
                selectAllFuel: false,
                fuelList: [],
                jobRoutes: []
            }
        },
        created(){
            this.id = this.$route.params.id;
            if(this.id > 0){
               this.title = 'Cập nhật chốt dầu';
               this.getById();
            }
            else{
               this.title = 'Thêm mới chốt dầu';
               this.getByAccount();
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            calFuelSubmited(){
                let normQuantity = 0;
                let km = 0;
                for (var i = 0; i < this.entity.jobRouteList.length; i++) {
                    normQuantity = normQuantity + parseFloat(this.entity.jobRouteList[i].fuel);
                    km = km + parseFloat(this.entity.jobRouteList[i].km);
                }
                this.entity.normQuantity = normQuantity.toFixed(2);
                let actualQuantity = 0;
                for (var j = 0; j < this.entity.fuelList.length; j++) {
                    actualQuantity = actualQuantity + parseFloat(this.entity.fuelList[j].quantity);
                }
                this.entity.actualQuantity = actualQuantity.toFixed(2);
                this.entity.diffQuantity = (this.entity.normQuantity - this.entity.actualQuantity).toFixed(2);
                this.entity.km = km.toFixed(2);
                if(this.entity.diffQuantity > 0){
                    this.entity.note = "Công ty thanh toán cho lái xe";
                }
                else{
                    this.entity.note = "Lái xe thanh toán cho Công ty";
                }
            },

            calSum(){
                this.entity.amount = (parseFloat(this.entity.price) * parseFloat(this.entity.diffQuantity)).toFixed(2);
            },

            delFuel(id){
                const index = this.entity.fuelList.findIndex(x => x.id == id);
                this.entity.fuelList.splice(index, 1);
                this.calFuelSubmited();
            },

            openFuel(){
                this.$refs.fuelList.open();
            },

            closeFuel(list){
                list.map((obj) => {
                    const index = this.entity.fuelList.findIndex(x => x.id == obj.id);
                    if(index == -1){
                        this.entity.fuelList.push(obj);
                    }
                });
                this.calFuelSubmited();
            },

            delJobRoute(id){
                const index = this.entity.jobRouteList.findIndex(x => x.id == id);
                this.entity.jobRouteList.splice(index, 1);
                this.calFuelSubmited();
            },

            openJobRoute(){
                this.$refs.jobRouteList.open();
            },

            closeJobRoute(list){
                list.map((obj) => {
                    const index = this.entity.jobRouteList.findIndex(x => x.id == obj.id);
                    if(index == -1){
                        this.entity.jobRouteList.push(obj);
                    }
                });
                this.calFuelSubmited();
            },

            close(){
                this.showDialog = false;
            },

            //Vehicle
            closeVehicle(item){
                this.licensePlate = item.licensePlate;
                this.entity.vehicleId = item.id;
                this.$refs.vehicleList.close();
            },

            getVehicleSelected(val){
                this.entity.vehicleId = val.id;
                this.licensePlate = val.licensePlate;
            },

            openVehicle(){
                this.$refs.vehicleList.open();
            },

            getVehicles(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, licensePlate:  searchTerm };
                vehicleService.getVehicles(search).then((response) => {
                    if(response.statusCode == 200){
                        this.vehicles = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //Driver
            closeDriver(item){
                this.driverName = item.fullName;
                this.entity.driverId = item.id;
                this.getFuelForSubmited();
                this.getJobRouteForSubmited();
                this.$refs.driverList.close();
            },

            getDriverSelected(val){
                this.entity.driverId = val.id;
                this.driverName = val.fullName;
                this.getFuelForSubmited();
                this.getJobRouteForSubmited();
            },

            openDriver(){
                this.$refs.driverList.open();
            },

            getDrivers(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, code:  searchTerm };
                driverService.getDrivers(search).then((response) => {
                    if(response.statusCode == 200){
                        this.drivers = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            submit(){
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                if(this.entity.jobRouteList.length == 0){
                    messageBox.showWarning("Vui lòng chọn định mức dầu");
                    return;
                }
                if(this.entity.fuelList.length == 0){
                    messageBox.showWarning("Vui lòng chọn đổ dầu thực tế");
                    return;
                }
                if(this.id > 0){
                    this.edit();
                }
                else{
                    this.add();
                }
            },

            add(){
                this.setLoading(true);
                this.entity.orderType = 1;
                fuelSubmitedService.add(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Thêm mới thành công");
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                 }).finally(() => { this.setLoading(false); });
            },

            edit(){
                this.setLoading(true);
                fuelSubmitedService.edit(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Cập nhật thành công");
                        this.showDialog = false;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getById(){
                this.setLoading(true);
                fuelSubmitedService.getById(this.id).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                        this.driverName = response.data.driver.fullName;
                        this.licensePlate = response.data.vehicle.licensePlate;
                        this.staffName = response.data.staff.fullName;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            back(){
                this.$router.push('/fuel-submited');
            },

            getByAccount(){
                this.setLoading(true);
                staffService.getByAccount().then((response) => {
                    if(response.statusCode == 200){
                        if(response.data != null){
                            this.entity.staff = response.data;
                            this.entity.staffId = response.data.id;
                            this.entity.deptId = response.data.departmentId;
                            this.staffName = response.data.fullName;
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            //Sales
            closeStaff(item){
                this.staffName = item.fullName;
                this.entity.staffId = item.id;
                this.entity.deptId = item.departmentId;
                this.$refs.staffList.close();
            },

            getStaffSelected(val){
                this.entity.staffId = val.id;
                this.entity.deptId = val.departmentId;
                this.staffName = val.fullName;
            },

            openStaff(){
                this.$refs.staffList.open();
            },

            getStaffs(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, name:  searchTerm };
                staffService.getStaffs(search).then((response) => {
                    if(response.statusCode == 200){
                        this.staffs = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },
        },
        watch: {
            driverName: function (val) { 
                if(val == ''){
                    this.entity.driverId = 0;
                    this.fuelList = [];
                }
            },
            licensePlate: function (val) { 
                if(val == ''){
                    this.entity.vehicleId = 0;
                }
            },
            companyName: function (val) { 
                if(val == ''){
                    this.entity.companyId = 0;
                }
            }
        },
        validations: {
            entity: {
                driverId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                staffId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                vehicleId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                createdDate: { required },
                price: { required },
                vat: { required },
                amount: { required },
            }
       }
     })

</script>

<style scoped>
    .job-routes, .fuel-list {
        width: 100%;
        height: 300px;
        border: 1px solid #ccc;
    }
    .body-list {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 250px;
        overflow: auto;
    }
    .job-routes .row-head, .fuel-list .row-head {
        display: flex;
        background-color: antiquewhite;
        padding: 5px 10px;
        align-items: center;
    }
    .job-routes .row-head .cell, .fuel-list .row-head .cell{
        font-weight: bold;
    }
    .job-routes .box-row, .fuel-list .box-row {
        display: flex;
        align-items: center;
        padding: 5px 10px;
    }
    .job-routes .cell:nth-child(1), .fuel-list .cell:nth-child(1) {
        width: 40px;
        vertical-align: middle;
        text-align: center;
        margin-right: 20px;
    }
    .job-routes .cell {
        width: 20%;
    }
    .fuel-list .cell:nth-child(2) {
        width: 35%;
    }
    .fuel-list .cell:nth-child(3) {
        width: 30%;
    }
    .fuel-list .cell:nth-child(4) {
        width: 15%;
    }
    .fuel-list .cell:nth-child(5) {
        width: 15%;
        text-align: right;
        padding-right: 10px;
    }
    .fuel-list .cell {
        width: 20%;
    }
    .job-routes .cell:nth-child(2){
        width: 30%;
    }
    .job-routes .cell:nth-child(3){
        width: 25%;
    }
    .job-routes .cell:nth-child(4){
        width: 12%;
    }
    .job-routes .cell:nth-child(5){
        width: 12%;
        text-align: right;
    }
    .job-routes .cell:nth-child(6){
        width: 15%;
        text-align: right;
    }
</style>