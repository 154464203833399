import httpClient  from '../api'; 
const API_CONTROLLER = 'fuel/'; 

export default { 
    delete(id){ 
        let url = API_CONTROLLER + 'delete';
        return httpClient.delete(url, {
            params: {
                id: id
            }
        });
    },

    add(data) { 
        let url = API_CONTROLLER + 'add';
        return httpClient.post(url, data);
    },

    edit(data) { 
        let url = API_CONTROLLER + 'edit';
        return httpClient.post(url, data);
    },

    getById(id) { 
        let url = API_CONTROLLER + 'get-by-id';
        return httpClient.get(url, {
            params: {
                id: id
            }
        });
    },

    getBudgetFuel(driverId, vehicleId) { 
        let url = API_CONTROLLER + 'get-budget-fuel';
        return httpClient.get(url, {
            params: {
                driverId: driverId,
                vehicleId: vehicleId
            }
        });
    },

    getForSubmited(search) { 
        let url = API_CONTROLLER + 'get-all-for-submited';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                driverId: search.driverId,
                vehicleId: search.vehicleId,
                fromDate: search.fromDate,
                toDate: search.toDate
            }
        });
    },

    filter(search) { 
        let url = API_CONTROLLER + 'filter';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                driverId: search.driverId,
                vehicleId: search.vehicleId,
                fromDate: search.fromDate,
                toDate: search.toDate
            }
        });
    },
}